// get all users
import {auth, functions} from '@/firebase';

const listAllUsers = functions.httpsCallable('listAllUsers');
const createUser = functions.httpsCallable('createUser');
const deleteUser = functions.httpsCallable('deleteUser');
const updateUser = functions.httpsCallable('updateUser');
const getUser = functions.httpsCallable('getUser');

const all = () => listAllUsers()
  .then((result) => result.data)
  .catch((error) => console.error(error));

const create = (user: any) => createUser(user)
  .then((response) => response.data)
  .catch((error) => console.error(error));

const remove = (uid: string) => deleteUser(uid)
  .then((result) => result.data)
  .catch((error) => console.error(error));

const update = (payload: any) => updateUser(payload)
  .then((result) => result.data)
  .catch((error) => console.error(error));

const get = (uid: string) => getUser(uid)
  .then((result) => result.data)
  .catch((error) => console.error(error));

const resetPassword = (email: string) => auth.sendPasswordResetEmail(email)
  .then(() => {
    return {success: 'Password reset email sent'};
  })
  .catch((error) => console.error(error));

export {all, create, remove, update, get, resetPassword};
